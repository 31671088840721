import axios from 'axios'
import authHeader from './auth-header'
// import helpers from "@/helpers/helper";

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class ECommerceService {
    createParams(data) {
        var page = data ? data.page : 1;
        const params = new URLSearchParams();
        params.append('page', page);
        params.append('size', data.size);
        params.append('search', data.search);
        params.append('start_date', data.start_date != null ? data.start_date : '');
        params.append('end_date', data.end_date != null ? data.end_date : '');
        return params;
    }
    getWebisiteCofig(data) {
        return axios.get(VUE_APP_BASE_URL + 'ecommerce/eco-config', { params: this.createParams(data), headers: authHeader(), }).then((resp) => {
            return resp.data;
        })
    }
    configureWebsite(formData) {
        if (formData.id) {
            return axios.put(VUE_APP_BASE_URL + 'ecommerce/eco-config/' + formData.id, formData, { headers: authHeader() });
        } else {
            return axios.post(VUE_APP_BASE_URL + 'ecommerce/eco-config', formData, { headers: authHeader() });
        }
    }
    updateLogo(item, config) {
        return axios.post(VUE_APP_BASE_URL + "ecommerce/logo-update", item, config);
    }

    getEcoCategory(data) {
        return axios.get(VUE_APP_BASE_URL + "ecommerce/eco-category", { params: this.createParams(data), headers: authHeader() }).then((response) => {
          return response.data;
        });
    }
    getEcoCategorySelect() {
        return axios.get(VUE_APP_BASE_URL + "ecommerce/eco-category-select", {headers: authHeader() }).then((response) => {
            console.log("SSSSSSSSSSSSSSSSSSSSSSSSSssssssselect array", response.data)
             return response.data;
        });
    }
    createEcoCategory(formData) {
        if (formData.id) {
            return axios.put(VUE_APP_BASE_URL + 'ecommerce/eco-category/' + formData.id, formData, { headers: authHeader() });
        } else {
            return axios.post(VUE_APP_BASE_URL + 'ecommerce/eco-category', formData, { headers: authHeader() });
        }
    }
    deleteEcoCategory(id) {
        return axios.delete(VUE_APP_BASE_URL + 'ecommerce/eco-category/' + id, { headers: authHeader() });
    }

    getEcoServices(data) {
        return axios.get(VUE_APP_BASE_URL + "ecommerce/eco-service", { params: this.createParams(data), headers: authHeader() }).then((response) => {
            return response.data;
        });
    }
    createEcoServices(formData) {
        if (formData.id) {
            return axios.put(VUE_APP_BASE_URL + 'ecommerce/eco-service/' + formData.id, formData, { headers: authHeader() });
        } else {
            return axios.post(VUE_APP_BASE_URL + 'ecommerce/eco-service', formData, { headers: authHeader() });
        }
    }
    deleteEcoServices(id) {
        return axios.delete(VUE_APP_BASE_URL + 'ecommerce/eco-service/' + id, { headers: authHeader() });
    }

    getEcoSolutions(data) {
        return axios.get(VUE_APP_BASE_URL + "ecommerce/eco-solution", { params: this.createParams(data), headers: authHeader() }).then((response) => {
            console.log("SSSSSSSSSSSSsservice", response.data)
            return response.data;
        });
    }
    createEcoSolutions(formData) {
        formData.body = formData.description;
        if (formData.id) {
            return axios.put(VUE_APP_BASE_URL + 'ecommerce/eco-solution/' + formData.id, formData, { headers: authHeader() });
        } else {
            return axios.post(VUE_APP_BASE_URL + 'ecommerce/eco-solution', formData, { headers: authHeader() });
        }
    }
    deleteEcoSolutions(id) {
        return axios.delete(VUE_APP_BASE_URL + 'ecommerce/eco-solution/' + id, { headers: authHeader() });
    }
}

export default new ECommerceService();