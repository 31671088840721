import moment from "moment";

export default {
  created: function () {},
  methods: {
    getVAT(selling_price) {
      let vatable_amount = this.calculateTaxableAmount(selling_price, 16);
      let vat = selling_price - vatable_amount;
      return vat.toFixed(2);
    },
    calculateTaxableAmount(totalInclusive, taxRate) {
      // Convert tax rate to a decimal
      taxRate = taxRate / 100;
      // Calculate the taxable amount
      let taxableAmount = totalInclusive / (1 + taxRate);
      return taxableAmount.toFixed(2);
    },
    async checkAuth() {
      let re = await axios
        .get(VUE_APP_BASE_URL + "users", { headers: authHeader() })
        .then(
          (res) => {
            return res.status;
          },
          (error) => {
            this.$router.push("/login");
            return error.response.status;
          }
        );
    },
    getInitials(name) {
      let array = name.split(" ");
      let ini = array[0].charAt(0);
      if (typeof array[1] !== "undefined") ini += array[1].charAt(0);
      else ini += array[0].charAt(1);
      return ini.toUpperCase();
    },
    getRandomColors() {
      let colors = [
        "dot bg-primary",
        "dot bg-info",
        "dot bg-warning",
        "dot bg-primary",
        "dot bg-danger",
      ];
      return colors[Math.floor(Math.random() * colors.length)];
    },
    forceReload() {
      if (localStorage.getItem("reloaded")) {
        localStorage.removeItem("reloaded");
        this.$store.commit("SET_LOADING", false);
      } else {
        this.$store.commit("SET_LOADING", false);
        localStorage.setItem("reloaded", "1");
        this.$router.go();
      }
    },
    detectOS() {
      var detectedOS = "Unknown OS";
      if (navigator.appVersion.indexOf("Mac") != -1) detectedOS = "MacOS";
      if (navigator.appVersion.indexOf("Win") != -1) detectedOS = "Windows";
      if (navigator.appVersion.indexOf("Linux") != -1) detectedOS = "Linux";
      return detectedOS;
    },
    detectBrowser() {
      let userAgentString = navigator.userAgent;
      var detectedBrowser = "Unknown Browser";
      if (userAgentString.indexOf("Chrome") > -1) detectedBrowser = "Chrome";
      if (
        userAgentString.indexOf("MSIE") > -1 ||
        userAgentString.indexOf("rv:") > -1
      )
        detectedBrowser = "Internet Exlorer";
      if (userAgentString.indexOf("Firefox") > -1) detectedBrowser = "Firefox";
      if (userAgentString.indexOf("Safari") > -1) detectedBrowser = "Safari";
      if (userAgentString.indexOf("OP") > -1) detectedBrowser = "Opera";
      return detectedBrowser;
    },
    subtractHours(date, hours) {
      const real_date = new Date(date);
      real_date.setHours(real_date.getHours() - hours);
      return real_date;
    },
    formatDateHuman(input) {
      input = new Date(input);
      input.setHours(input.getHours() - 4);
      return moment(input).format("MMMM Do YYYY");
    },
    formatToCurrency: function (amount) {
      try {
        return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      } catch (error) {
        return amount;
      }
      // return "Ksh. " + amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    truncateText(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },
    formatDate(input) {
      // input = new Date(input);
      // input.setHours(input.getHours() - 3);
      // return moment(input).format("DD-MM-YYYY h:mm:ss");
      return moment(input).format("DD-MM-YYYY h:mm");
    },
    createReference(date, ticket_id, prefix) {
      return (
        prefix + "/" + moment(date).format("YYYYDDMMhmm") + "/" + ticket_id
      );
    },
    formatDate(date) {
      //"DD-MM-YYYY h:mm"
      date = new Date(date);
      var day = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();
      var hours = date.getHours();
      var minutes = date.getMinutes();
      //var ampm = hours >= 12 ? 'PM' : 'AM';
      // var h1,m1;
      // h1 = h1 < 10 ? '0' + hours : hours;
      // m1 = m1 <10 ? '0' + minutes : minutes;
      hours = hours % 24;
      // hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        "/ " +
        month +
        " /" +
        year +
        " at " +
        hours +
        ":" +
        minutes +
        " " +
        " hrs "; //ampm;
      return strTime;
    },
    generateRandom(min, max) {
      return Math.floor(min + Math.random() * max);
    },
    formatDate(date) {
      date = new Date(date);
      var day = date.getDate(); // getDay returns the day of the week, use getDate to get the day of the month
      var month = date.getMonth() + 1; // add 1 to get the month number starting from 1 (January)
      var year = date.getFullYear();
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },

    // formatDate(date) {
    //   date = new Date(date)
    //   var day = date.getDay();
    //   var month = date.getMonth();
    //   var year = date.getFullYear();
    //   var hours = date.getHours();
    //   var minutes = date.getMinutes();
    //   var ampm = hours >= 12 ? 'PM' : 'AM';
    //   hours = hours % 12;
    //   hours = hours ? hours : 12; // the hour '0' should be '12'
    //   minutes = minutes < 10 ? '0' + minutes : minutes;
    //   var strTime = day+' /'+ month+' /'+ year+' /'+hours + ':' + minutes + ' ' + ampm;
    //   return strTime;

    // },

    //   getTimeGreetings() {
    //     const time = new Date().getHours();
    //     let greeting;
    //     if (time < 10) greeting = "Good Morning";
    //     else if (time >= 12 && time <= 17) greeting = "Good Afternoon";
    //     else if (time >= 17 && time <= 24) greeting = "Good Evening";
    //     return greeting;
    //   },
    //   hasRight(slug) {
    //     let userRole = localStorage.getItem("user")
    //       ? JSON.parse(localStorage.getItem("user")).permissions
    //       : [];
    //     return Object.keys(userRole).includes(slug);
    //   },
    // },
    computed: {
      today() {
        return new Date().toDateString();
      },
      user() {
        return this.$store.state.auth.user;
      },
      table() {
        return localStorage.getItem("table") == null
          ? {}
          : JSON.parse(localStorage.getItem("table"));
      },
    },
  },
};
