import moment from "moment";

function formatDate(input) {
  return moment(input).format("DD-MM-YYYY HH:mm:ss");
}
function formatDateInvoice(input) {
  return moment(input).format("DD/MM/YYYY");
}

function generateFormattedDate() {
  const currentDate = new Date();
  // Extract day, month, and year
  const day = currentDate.getDate().toString().padStart(2, "0");
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Note: Months are zero-based
  const year = currentDate.getFullYear().toString();
  // Format the date
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
}

function generateFormattedTime() {
  const currentTime = new Date();
  // Extract hours, minutes, and seconds
  const hours = currentTime.getHours().toString().padStart(2, "0");
  const minutes = currentTime.getMinutes().toString().padStart(2, "0");
  const seconds = currentTime.getSeconds().toString().padStart(2, "0");
  // Format the time
  const formattedTime = `${hours}:${minutes}:${seconds}`;
  return formattedTime;
}

export default {
  formatDate,
  formatDateInvoice,
  generateFormattedDate,
  generateFormattedTime,
};
